import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

const BlogIndexFlexContainer = styled.div`
  overflow-y: scroll;
  align-content: center;
  margin: 100px 5%;
  padding: 5px;
`
const FlexItem = styled(Link)`
  display: block;
  position: relative;
  border: 20px solid transparent;
  min-height: 300px;
  height: 35vh;
  background-color: transparent;
  transition: 0.4s;
  text-decoration: none;

  & img {
    width: 100%;
  }
  & .gatsby-image-wrapper {
    width: 100%;
  }
  &:hover {
    transform: translateY(-3px);
    color: #abbddb;
  }
`
const ImageTint = styled.div`
  position: absolute;
  z-index: 64;
  height: 100%;
  width: 100%;
  background-color: rgba(0.5, 0.5, 0.5, 0.6);
  transform: translate(0%, -100%);
`

export const TitleContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 66;
  color: white;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 40px 15%;
  box-shadow: 5px 5px 10px grey;

  & .publish-date {
    position: absolute;
    margin: 25px 0px;
    bottom: 20px;
  }
`
const PostTitle = styled.p`
  position: relative;
  color: white;
  width: 100%;
  font-size: 1.5em;
`

const BlogHeroImage = styled(Image)`
  height: 100%;
`
const EmptyImage = styled.div`
  height: 100%;
`
const BlogPost = ({ node }) => {
  const image = node.featureImage
  return (
    <FlexItem to={node.slug}>
      {node.featureImage ? (
        <BlogHeroImage
          sizes={{
            ...image.fluid,
            base64: image.sqip.dataURI,
          }}
        />
      ) : (
        <EmptyImage />
      )}

      <ImageTint />
      <TitleContainer>
        <PostTitle>{node.title}</PostTitle>
        <p className="publish-date">{node.publishDate}</p>
      </TitleContainer>
    </FlexItem>
  )
}

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    return (
      <>
        <Helmet
          title="Blog"
          meta={[
            { name: 'description', content: 'Sample' },
            {
              name: 'keywords',
              content: 'Software, music, Loud Mouth, Dem Suckaz',
            },
          ]}
        />
        <BlogIndexFlexContainer>
          {data.allContentfulBlogPost.edges.map((edge) => (
            <BlogPost node={edge.node} key={edge.node.id} />
          ))}
        </BlogIndexFlexContainer>
      </>
    )
  }
}

BlogPost.propTypes = {
  node: PropTypes.object.isRequired,
}
BlogIndex.propTypes = {
  data: PropTypes.object.isRequired,
}
export default BlogIndex

export const query = graphql`
  query {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          id
          title
          publishDate(formatString: "MMMM Do, YYYY")
          slug
          markdown {
            childMarkdownRemark {
              excerpt(pruneLength: 280)
            }
          }
          featureImage {
            ...SmallSqipPreview
          }
        }
      }
    }
  }
`
